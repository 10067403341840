const tableRowClassName = ({ row, rowIndex }) => {
  if (row.bill_status != 0) {
    return "erro-row";
  }
};
const contentTableConfig = {
  propList: [
    { prop: "merchants_stall_name", label: "表号", minWidth: "80" },

    {
      prop: "property_merchants_stall_type",
      label: "商铺名称",
    },
    {
      prop: "updateAt",
      label: "购电总额",
    },
    {
      prop: "updateAt",
      label: "购电电量",
    },
    {
      prop: "updateAt",
      label: "已用金额",
    },
    {
      prop: "updateAt",
      label: "已用电量",
    },
    {
      prop: "updateAt",
      label: "创建时间",
    },
    {
      slotName: "operation",
      label: "操作",
      minWidth: "100",
    },
  ],
  showIndexColumn: false,
  showSelectColumn: false,
  //childrenProps 将直接传入elTable
  childrenProps: {
    rowClassName: tableRowClassName,
  },
};
const searchList = [
  {
    type: "input",
    placeholder: "请输入商户名称",
    field: "name",
  },
  {
    type: "input",
    placeholder: "请输入电表号",
    field: "name",
  },
  {
    type: "select",
    placeholder: "请选择合闸状态",
    field: "type",
    options: [
      { label: "合闸", value: 0 },
      { label: "拉闸", value: 1 },
    ],
  },
  {
    type: "select",
    placeholder: "请选择联网状态",
    field: "type",
    options: [
      { label: "连接", value: 0 },
      { label: "断开", value: 1 },
    ],
  },
  {
    type: "radio",
    placeholder: "",
    field: "radio",
    options: [
      {
        label: 1,
        text: "大于",
      },
      {
        label: 2,
        text: "小于",
      },
    ],
    label: "剩余",
  },
  {
    type: "input",
    placeholder: "请输入用量",
    field: "elec",
  },
  {
    type: "radio",
    placeholder: "",
    field: "radio",
    options: [
      {
        label: 1,
        text: "大于",
      },
      {
        label: 2,
        text: "小于",
      },
    ],
    label: "使用",
  },
  {
    type: "input",
    placeholder: "请输入用量",
    field: "elec",
  },
  {
    type: "daterange",
    placeholder: "选择时间",
    field: "time",
  },
];

const modalFormList = [
  {
    type: "radio",
    label: "是否需要退款",
    field: "a",
    options: [
      {
        label: 1,
        text: "是",
      },
      {
        label: 2,
        text: "否",
      },
    ],
    requireRules: true, //是否使用rules
    rules: [{ required: true, message: "请选择是否退款", trigger: "blur" }],
  },
  {
    type: "input",
    label: "剩余电量",
    field: "b",
    rules: [{ required: true, message: "请选择是否退款", trigger: "blur" }],
  },
  {
    type: "input",
    label: "剩余金额",
    field: "c",
  },
  {
    type: "input",
    label: "退款金额",
    field: "d",
    rules: [{ required: true, message: "请输入金额", trigger: "blur" }],
  },
  {
    type: "textarea",
    label: "备注",
    field: "e",
  },
];
export { contentTableConfig, searchList, modalFormList };
