<template>
  <div class="page-wrapper">
    <RechargePage
      :pageName="pageName"
      :type="type"
      :contentTableConfig="contentTableConfig"
      :searchList="searchList"
      :createModalFormList="createModalFormList"
    ></RechargePage>
  </div>
</template>
<script>
import RechargePage from "../../cpns/rechargePage.vue";
import { searchList, contentTableConfig, createModalFormList } from "./config";

export default {
  components: {
    RechargePage,
  },

  props: {},
  data() {
    return {
      searchList,
      contentTableConfig,
      createModalFormList,
      pageName: "water_rechargeRecord",
      type: 1,
    };
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped></style>
