<template>
  <div class="page-wrapper">
    <PageContent
      class="page-content"
      :contentTableConfig="contentTableConfig"
      :pageInfo="pageInfo"
      :search-list="searchList"
      :pageName="pageName"
      @search="hanldeSearch"
      @update:page="handleChangePage"
    >
    </PageContent>
  </div>
</template>
<script>
import PageContent from "../cpns/pageContent.vue";
import pageBaseMixin from "@/mixin/pageBase.js";
import {
  electricSearchList as searchList,
  electricTableConfig as contentTableConfig,
} from "./config";

export default {
  components: {
    PageContent,
  },
  mixins: [pageBaseMixin],

  data() {
    return {
      contentTableConfig,
      searchList,
      pageName: "electricTotal",
    };
  },
  methods: {},
  mounted() {
    this.getDataList();
  },
};
</script>
<style lang="scss" scoped></style>
