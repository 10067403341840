<template>
  <div class="page-wrapper">
    <PageContent
      class="page-content"
      :contentTableConfig="contentTableConfig"
      :pageInfo="pageInfo"
      :search-list="searchList"
      :pageName="pageName"
      @search="hanldeSearch"
      @update:page="handleChangePage"
    >
    </PageContent>
  </div>
</template>
<script>
import PageContent from "../cpns/pageContent.vue";
import { contentTableConfig, searchList } from "./config";
import pageBaseMixin from "@/mixin/pageBase.js";

export default {
  components: {
    PageContent,
  },
  mixins: [pageBaseMixin],

  data() {
    return {
      contentTableConfig,
      searchList,
      pageName: "clearRecord",
    };
  },
  methods: {},
  mounted() {
    this.getDataList();
  },
};
</script>
<style lang="scss" scoped></style>
