//清零页面tabel 内容
const contentTableConfig = {
  propList: [
    { prop: "number", label: "表号", minWidth: "80" },

    {
      prop: "contract",
      label: "商铺号",
      slotName: "createAt",
    },
    {
      prop: "shanghuhao",
      label: "商户号",
    },
    {
      prop: "name",
      label: "商户姓名",
    },
    {
      prop: "money",
      label: "清零时剩余金额",
      slotName: "money",
    },
    {
      prop: "electric",
      label: "清零时剩余电量",
    },
    {
      prop: "electric",
      label: "备注",
    },
    {
      prop: "clearTime",
      label: "清零时间",
    },
    {
      prop: "reMoney",
      label: "退款金额",
    },
    {
      label: "操作",
      slotName: "handler",
    },
  ],
  showIndexColumn: false,
  showSelectColumn: true,
};

//清零搜索
const searchList = [
  {
    type: "input",
    placeholder: "请输入商户名称",
    field: "name",
  },
  {
    type: "select",
    placeholder: "请选择状态",
    field: "type",
    options: [
      { label: "连接", value: 0 },
      { label: "断开", value: 1 },
    ],
  },
  {
    type: "daterange",
    placeholder: "选择时间",
    field: "time",
  },
];
//充值记录tabel
const rechargeTableConfig = {
  propList: [
    { prop: "meter_number", label: "表号", minWidth: "80" },
    { prop: "merchants_name", label: "商户名称", minWidth: "80" },
    { prop: "merchants_no", label: "商户号", minWidth: "80" },
    { prop: "stall_name", label: "商铺号", minWidth: "80" },
    {
      prop: "pay_no",
      label: "充值流水号",
    },
    {
      prop: "pay_price",
      label: "充值金额",
    },
    {
      prop: "price",
      label: "单价",
    },
    // {
    //   prop: "price",
    //   label: "充值电量",
    //   slotName: "price",
    // },
    {
      prop: "pay_time",
      label: "支付时间",
    },
    {
      prop: "pay_type",
      label: "充值方式",
      slotName: "pay_type",
    },

    {
      prop: "pay_status",
      label: "充值状态",
      slotName: "pay_status",
    },
    {
      prop: "remarks",
      label: "备注",
    },
    {
      prop: "print_count",
      label: "打印次数",
    },
    {
      slotName: "invoicing",
      prop: "invoicing",
      label: "是否开票",
    },
    {
      slotName: "operation",
      label: "凭证",
      minWidth: "100"
    },
    {
      slotName: "make",
      label: "开票",
      minWidth: "100"
    }
  ],
  showIndexColumn: false,
  showSelectColumn: false,
};
const rechargeSearcList = [
  {
    type: "input",
    placeholder: "请输入表号",
    field: "meter_number",
  },
  {
    type: "input",
    placeholder: "请输入商户名称",
    field: "merchants_name",
  },
  {
    type: "input",
    placeholder: "请输入商户号",
    field: "merchants_no",
  },
  {
    type: "input",
    placeholder: "请输入商铺号",
    field: "stall_name",
  },
  {
    type: "select",
    placeholder: "请选择是否打印",
    field: "print_count",
    options: [
      { label: '已打印', value: 1 },
      { label: '未打印', value: 0 }
    ]
  },
  {
    type: "select",
    placeholder: "请选择开票",
    field: "invoicing",
    options: [
      { label: '已开票', value: 1 },
      { label: '未开票', value: 0 }
    ]
  },
  {
    type: "daterange",
    placeholder: "选择支付时间",
    field: "pay_time",
  },
];
//充值金额弹窗
const createModalFormList = [
  {
    type: "input",
    label: "表号",
    field: "meter_number",
    requireRules: true,
    placeholder: "请输入表号",
    rules: [{ required: true, message: "请输入表号", trigger: "blur" }],
  },
  {
    type: "select",
    label: "商铺号",
    field: "merchants_id",
    disabled: false,
    requireRules: true,
    rules: [{ required: true, message: "请输入商铺号", trigger: "change" }],
  },
  {
    type: "input",
    label: "单价",
    field: "price",
    disabled: true,
    requireRules: true,
    rules: [{ required: true, message: "请输入正确的表号", trigger: "change" }],
  },

  {
    type: "input",
    label: "充值金额",
    field: "pay_price",
    requireRules: true,
    placeholder: "请输入充值金额",
    rules: [{ required: true, message: "请输入充值金额", trigger: "blur" }],
  },
  {
    type: "radio",
    label: "缴费方式",
    field: "pay_type",
    options: [
      {
        label: 30,
        text: "现金",
      },
      {
        label: 10,
        text: "微信二维码",
      },
      // {
      //   label: 20,
      //   text: "扫码枪",
      // },
    ],
    requireRules: true,
    rules: [{ required: true, message: "请选择收款方式", trigger: "change" }],
  },
  {
    type: "textarea",
    label: "备注",
    field: "remarks",
    placeholder: "请输入备注",
  },
];
//用电统计tabel
const getSummaries = (param) => {
  const { columns, data } = param;
  const sums = [];
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = "总价";
      return;
    }
    const values = data.map((item) => Number(item[column.property]));
    if (!values.every((value) => isNaN(value))) {
      sums[index] = values.reduce((prev, curr) => {
        const value = Number(curr);
        if (!isNaN(value)) {
          return prev + curr;
        } else {
          return prev;
        }
      }, 0);
      sums[index] += " 元";
    } else {
      sums[index] = "/";
    }
  });
  return sums;
};
const electricTableConfig = {
  propList: [
    { prop: "number", label: "表号", minWidth: "80" },

    {
      prop: "contract",
      label: "商铺号",
      slotName: "createAt",
    },
    {
      prop: "name",
      label: "商户号",
    },

    {
      prop: "electric",
      label: "用电量",
    },
    {
      prop: "em",
      label: "用电金额",
    },
  ],
  showIndexColumn: false,
  showSelectColumn: false,
  //childrenProps 将直接传入elTable
  childrenProps: {
    showSummary: true,
    summaryMethod: getSummaries,
  },
};
const electricSearchList = [
  {
    type: "input",
    placeholder: "请输入表号",
    field: "name",
  },

  {
    type: "daterange",
    placeholder: "选择时间",
    field: "time",
  },
  {
    type: "radio",
    placeholder: "",
    field: "radio",
    options: [
      {
        label: 1,
        text: "大于",
      },
      {
        label: 2,
        text: "小于",
      },
    ],
    label: "剩余电量",
  },
  {
    type: "input",
    placeholder: "请输入电量",
    field: "elec",
  },
];
//商户用电统计
const merchantElecTableConfig = {
  propList: [
    { prop: "number", label: "表号", minWidth: "80" },

    {
      prop: "contract",
      label: "商铺号",
      slotName: "createAt",
    },
    {
      prop: "shanghuhao",
      label: "商户号",
    },
    {
      prop: "shanghuhao",
      label: "商户姓名",
    },
    {
      prop: "shanghuhao",
      label: "清零时剩余金额",
    },
    {
      prop: "shanghuhao",
      label: "清零时剩余电量",
    },
    {
      prop: "shanghuhao",
      label: "退款金额",
    },
    {
      prop: "electric",
      label: "清零时间",
    },
    {
      prop: "electric",
      label: "备注",
    },
  ],
  showIndexColumn: false,
  showSelectColumn: false,
};
const merchantElecSearchList = [
  {
    type: "input",
    placeholder: "请输入商户名称",
    field: "name",
  },
  {
    type: "input",
    placeholder: "请输入商铺名称",
    field: "name",
  },
  {
    type: "input",
    placeholder: "请输入表号",
    field: "name",
  },

  {
    type: "daterange",
    placeholder: "选择时间",
    field: "time",
  },
];
export {
  contentTableConfig,
  searchList,
  rechargeTableConfig,
  rechargeSearcList,
  electricTableConfig,
  electricSearchList,
  merchantElecTableConfig,
  merchantElecSearchList,
  createModalFormList,
};
