<template>
  <div>
    <div id="GFGS" style="
        position: relative;
        width: 100%;
        text-align: center;
        font-family: 'Microsoft YaHei';
        color: black;
        padding: 0;
        margin: 0;
      ">
      <div style="
          font-size: 18px;
          font-weight: bold;
          color: black;
          text-align: center;
          letter-spacing: 10px;
        ">
        重庆嘉瑜升商业管理有限公司{{ detailDataRowObj.billTitemNo }} {{ detailDataRowObj.types == 1 ? '水费' : '电费' }}收据
      </div>
      <div style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        ">
        <span style="color: black">商户：{{ detailDataRowObj.merchants_name }}</span>
        <span style="color: black">商铺：{{ detailDataRowObj.stall_name }}</span>
        <span style="color: black">收据编号：{{ detailDataRowObj.pay_no || "" }}</span>
      </div>
      <table border style="
          border-collapse: collapse;
          width: 100%;
          text-align: center;
          color: black;
          border-color: black;
        ">
        <tr style="height: 30px; border-color: black">
          <td style="width: 20%; color: black; border-color: black">
            表号
          </td>
          <td style="width: 20%; color: black; border-color: black">
            充值金额
          </td>
          <td style="width: 20%; color: black; border-color: black">
            单价
          </td>
          <td style="width: 20%; color: black; border-color: black">
            支付方式
          </td>
          <td style="width: 20%; color: black; border-color: black">
            充值时间
          </td>
        </tr>
        <tr style="height: 30px; border-color: black" v-for="(item, index) in detailDataRow" :key="index">
          <td style="width: 20%; color: black; border-color: black">
            {{ item.meter_number || "" }}
          </td>
          <td style="width: 20%; color: black; border-color: black">
            {{ item.pay_price || 0 }}
          </td>
          <td style="width: 20%; color: black; border-color: black">
            {{ item.price || 0 }}
          </td>
          <td style="width: 20%; color: black; border-color: black">
            {{ item.pay_name || '' }}
          </td>
          <td style="width: 20%; color: black; border-color: black">
            {{ item.pay_time || "" }}
          </td>
        </tr>
        <tr style="height: 30px; border-color: black" v-if="!detailDataRow || detailDataRow.length === 0">
          <td style="width: 20%; color: black; border-color: black"></td>
          <td style="width: 20%; color: black; border-color: black"></td>
          <td style="width: 20%; color: black; border-color: black"></td>
          <td style="width: 20%; color: black; border-color: black"></td>
          <td style="width: 20%; color: black; border-color: black"></td>
        </tr>
        <tr style="height: 30px; border-color: black">
          <td style="width: 20%; color: black; border-color: black">备注</td>
          <td colspan="11" style="color: black; border-color: black; text-align: left">
            {{ detailDataRowObj.remark }}
          </td>
        </tr>
      </table>
      <div style="
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        ">
        <span style="color: black">开票人：{{ userName }}</span>
        <span style="color: black">打印时间：{{ detailDataRowObj.printTime }}</span>
      </div>
      <div style="width: 100%; display: flex; align-items: center">
        <span style="color: black">欢迎关注微信公众号：海领农产品批发市场。</span>
      </div>
      <!-- <div style="width: 100%; display: flex; justify-content: flex-end; height: 0">
        <img :src="detailDataRowObj.electronicSeal" style="
            position: relative;
            width: 100px;
            height: 100px;
            bottom: 80px;
            right: 20px;
            border-radius: 50%;
          " />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "GFG",
  props: {
    detailDataRowObj: {
      type: Object,
      default: () => {
        return {
          merchants_stall_name: "",
          voucherName: "",
          electronicSeal: "",
          voucher_number: "",
          totalMoney: 0,
          preMoney: "",
          remark: "",
          userName: "",
          printTime: "",
          billTitemNo: "",
        };
      },
    },
    detailDataRow: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      userName: ''
    }
  },
  created () {
    let userInfo = window.localStorage.getItem("userInfo");
    if (userInfo) {
      this.userName = JSON.parse(userInfo).worker.name || "";
    }
  },
  methods: {
    getNumber (item) {
      let total = 0;
      total =
        item.coupon_price +
        item.balance_price +
        item.give_discount +
        item.zero_price;
      return total.toFixed(2) || 0.0;
    },
  },
};
</script>

<style scoped></style>
