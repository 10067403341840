<template>
  <div class="page-wrapper">
    <RechargePage :type="type" :create-modal-form-list="createModalFormList" :contentTableConfig="contentTableConfig"
      :search-list="searchList" :page-name="pageName">
    </RechargePage>
  </div>
</template>
<script>
import RechargePage from "../cpns/rechargePage.vue";
import {
  rechargeSearcList as searchList,
  rechargeTableConfig as contentTableConfig,
  createModalFormList,
} from "./config";

export default {
  components: {
    RechargePage
  },
  props: {},
  data () {
    return {
      contentTableConfig,
      searchList,
      createModalFormList,
      pageName: "rechargeRecord",
      type: 2,
      sorceFormData: {},
      timeN: null
    };
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped></style>
