<template>
  <div class="page-wrapper">
    <PageContent class="page-content" :contentTableConfig="contentTableConfig" :pageInfo="pageInfo"
      :search-list="searchList" :pageName="pageName" @search="hanldeSearch" @update:page="handleChangePage">
      <template #btn>
        <MyButton title="充值" :left="true" @click="hanldeAdd()">
          <template slot="preImage">
            <img src="@/unit/img/zj.png" alt="" />
          </template>
        </MyButton>
      </template>
      <template #pay_type="{ row }">
        {{ row.pay_type == 30 ? "现金" : row.pay_type == 20 ? '二维码' : '微信' }}
      </template>
      <template #pay_status="{ row }">
        {{ row.pay_status == 10 ? "已支付" : "未支付" }}
      </template>
      <template #price="{ row }">
        {{ Math.round((row.pay_price / row.price) * 100) / 100 }}
      </template>
      <template #invoicing="{ row }">
        {{ row.invoice_id == 0 ? "未开票" : row.invoice_id == 1 ? "已开票" : '' }}
      </template>
      <template #operation="{ row }">
        <div>
          <pop popLeft tips="查看" @myclick="examine(row)">
            <img class="icon" src="@/assets/img/icon/chakan.png" alt="" />
          </pop>
        </div>
      </template>
      <template #make="{ row }">
        <div>
          <pop popLeft tips="开票" @myclick="makeAdd(row)" v-if="row.invoice_id == 0">
            <img class="icon" src="@/assets/img/icon/pingzheng.png" alt="" />
          </pop>
          <pop popLeft tips="查看" @myclick="examineMake(row)" v-else>
            <img class="icon" src="@/assets/img/icon/chakan.png" alt="" />
          </pop>
        </div>
      </template>
    </PageContent>
    <PageModal labelWidth="100px" ref="addModalRef" @meter_number="hanldeGetDetail" :form-field-list="createModalFormList"
      :sorceFormData="sorceFormData" title="充值" :selectOptions="stallOptions" @submit="handleAddSubmit"></PageModal>
    <PageModal @close="hanldeClose" title="充值" width="400px" ref="rechargeRef">
      <template #content>
        <div ref="qrcodeRef" id="qrcode" style="margin: auto; width: 300px; height: 300px"></div>
      </template>
      <template #footer>
        <el-button type="info" @click="hanldeClose" style="margin: 40px auto 0 155px">关闭</el-button>
      </template>
    </PageModal>
    <el-dialog title="收费凭证" width="1230px" :visible.sync="vouchervasible" :close-on-click-modal="false">
      <GfG :detailDataRowObj="detailDataRowObj" :detailDataRow="detailDataRow"></GfG>
      <template slot="footer">
        <MyButton type="primary" @click="printDiv">打印</MyButton>
        <MyButton left @click="vouchervasible = false">关闭</MyButton>
      </template>
    </el-dialog>
    <el-dialog title="开票" width="500px" :visible.sync="makeVasible" :close-on-click-modal="false" @close="closeMake">
      <el-form :model="makeParams" :rules="makeParamsRules" ref="makeRuleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="开票金额" prop="amount">
          <el-input v-model.number="makeParams.amount" type="number" :disabled="isDisabled"
            placeholder="请输入开票金额"></el-input>
        </el-form-item>
        <el-form-item label="发票号" prop="invoice_number">
          <el-input v-model="makeParams.invoice_number" :disabled="isDisabled" placeholder="请输入发票号"></el-input>
        </el-form-item>
        <el-form-item label="开票日期" prop="invoice_time">
          <el-date-picker v-model="makeParams.invoice_time" :disabled="isDisabled" style="width:100%"
            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="makeParams.memo" :disabled="isDisabled" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="发票图片" prop="image">
          <div class="upload-imgx-box" v-if="makeParams.image && !isDisabled">
            <img class="upload-img" :src="makeParams.image" alt="" />
          </div>
          <div class="upload-imgx-box" v-if="makeParams.image && isDisabled">
            <!-- <img class="upload-img" :src="makeParams.image" alt="" /> -->
            <el-image style="width: 100px; height: 100px" :src="makeParams.image" :preview-src-list="[makeParams.image]">
            </el-image>
          </div>
          <el-upload action="" :auto-upload="false" accept="image/*" :show-file-list="false" :on-change="filechange"
            v-if="!isDisabled">
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <MyButton type="primary" @click="makeConfirm" v-if="!isDisabled">确认</MyButton>
        <MyButton left @click="makeVasible = false">关闭</MyButton>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import PageContent from "./pageContent.vue";
import PageModal from "./pageModal.vue";
import GfG from "./GFG.vue";
import pageBaseMixin from "@/mixin/pageBase.js";
import {
  getMeterInfo,
  addMeterPrice,
  checkRechargeStatus,
  getPrint,
  makeConfirm,
  getMake
} from "../service/meter";
import QRCode from "qrcodejs2";
export default {
  components: {
    PageContent,
    PageModal,
    GfG
  },
  mixins: [pageBaseMixin],
  props: {
    contentTableConfig: {
      type: Object,
      default: () => ({}),
    },
    searchList: {
      type: Array,
      default: () => [],
    },
    createModalFormList: {
      type: Array,
      default: () => [],
    },
    pageName: {
      type: String,
      default: "",
    },
    type: {
      type: [String, Number],
      default: "",
    },
  },
  data () {
    var validate = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入开票金额'));
      } else if (value <= 0) {
        callback(new Error('请输入正确的开票金额'));
      } else {
        if (this.makeParams.amount !== '') {
          this.$refs['makeRuleForm'].clearValidate('amount');
        }
        callback();
      }
    };
    return {
      sorceFormData: {},
      timeN: null,
      stallOptions: {},
      vouchervasible: false,
      detailDataRowObj: {},
      detailDataRow: [],
      makeVasible: false,
      makeParams: {
        amount: '',
        invoice_number: '',
        invoice_time: '',
        image: '',
        memo: '',
        pay_id: ''
      },
      makeParamsRules: {
        amount: [
          { validator: validate, trigger: 'blur' }
        ],
        invoice_number: [
          { required: true, message: '请输入发票号', trigger: 'blur' }
        ],
        invoice_time: [
          { required: true, message: '请选择开票日期', trigger: 'change' }
        ],
        image: [
          { required: true, message: '请上传发票图片', trigger: 'change' }
        ]
      },
      isDisabled: false
    };
  },
  methods: {
    closeMake () {
      this.makeParams = {
        amount: '',
        invoice_number: '',
        invoice_time: '',
        image: '',
        memo: '',
        pay_id: ''
      };
      this.$refs['makeRuleForm'].clearValidate('amount');
      this.$refs['makeRuleForm'].clearValidate('invoice_number');
      this.$refs['makeRuleForm'].clearValidate('invoice_time');
      this.$refs['makeRuleForm'].clearValidate('image');
      this.isDisabled = false;
    },
    examineMake (row) {
      getMake(row.invoice_id).then((res) => {
        this.makeParams = res.data;
        this.isDisabled = true;
        this.makeVasible = true;
      })
    },
    makeConfirm () {
      this.$refs['makeRuleForm'].validate((valid) => {
        if (valid) {
          makeConfirm(this.makeParams).then((res) => {
            this.$common.notifySuccess('开票成功');
            this.getDataList();
            this.makeVasible = false;
          });
        }
      });
    },
    filechange (file) {
      let obj = new FormData();
      obj.append("file", file.raw);
      this.$request.HttpPost("/system/upload/upImg", obj).then((res) => {
        this.makeParams.image = process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl;
        this.$refs["makeRuleForm"].clearValidate("image");
      });
    },
    makeAdd (row) {
      this.isDisabled = false;
      this.makeParams.pay_id = row.pay_id;
      this.makeVasible = true;
    },
    examine (row) {
      this.detailDataRowObj = {
        ...row,
        billTitemNo: row.pay_time.slice(0, 10),
        printTime: this.$common.formatTimeHms(new Date(), true),
        types: this.type
      };
      this.detailDataRow = [{
        ...row,
        pay_name: row.pay_type == 30 ? "现金" : row.pay_type == 20 ? '二维码' : '微信',
      }];
      this.vouchervasible = true;
    },
    printDiv () {
      this.$nextTick(() => {
        let divContents = document.getElementById("GFGS").innerHTML;
        let a = window.open("", "");
        a.document.write("<html>");
        a.document.write("<body >");
        a.document.write(divContents);
        a.document.write("</body></html>");
        a.document.close();
        a.print();
        getPrint(this.detailDataRowObj.pay_id).then(() => {
          this.getDataList();
          this.vouchervasible = false;
        });
      });
    },
    hanldeSearch (value) {
      this.pageInfo.pageNum = 1;
      const serchPatams = {
        meter_number: value.meter_number,
        beginTime: value?.pay_time?.[0] ?? null,
        endTime: value?.pay_time?.[1] ?? null,
      };
      this.getDataList({
        ...serchPatams,
        ...value
      });
    },
    hanldeAdd () {
      this.$refs.addModalRef.open();
    },
    async hanldeGetDetail (value) {
      if (!value) return;
      const { data } = await getMeterInfo(value);
      if (data.bindMerchants) {
        this.stallOptions["merchants_id"] = data?.bindMerchants.map((item) => ({
          ...item,
          value: item.merchants_id,
          label: `${item.merchants_name}-${item.merchants_number}-${item.merchants_stall_name}`,
        }));
        this.$set(this.$refs.addModalRef.formData, 'merchants_id', data.bindMerchants[0].merchants_id);
        this.$set(this.$refs.addModalRef.formData, 'merchants_name', data.bindMerchants[0].merchants_name);
        this.$set(this.$refs.addModalRef.formData, 'merchants_no', data.bindMerchants[0].merchants_number);
      }
      this.$set(this.$refs.addModalRef.formData, "price", data.result.price);
    },
    handleAddSubmit (value) {
      addMeterPrice({ ...value, meter_type: this.type }).then((res) => {
        if (value.pay_type == 30) {
          this.$message.success("操作成功");
          this.$refs.addModalRef.close();
          this.getDataList();
          return;
        }
        this.$refs.rechargeRef.open();

        this.mapSet(res);
      });
    },

    mapSet (res) {
      this.$nextTick(() => {
        new QRCode(this.$refs.qrcodeRef, {
          text: res.data.payment_url.url,
          width: 300,
          height: 300,
          colorDark: "#000000",
          colorLight: "#ffffff",
        });
        this.timeN = null;
        this.timeN = setInterval(() => {
          checkRechargeStatus(res.data.pay_no)
            .then((res) => {
              if (res.data.pay_status == 10) {
                this.$message({
                  message: "缴费成功",
                  type: "success",
                });
                clearInterval(this.timeN);
                this.$refs.rechargeRef.close();
                this.$refs.addModalRef.close();
                this.getDataList();
              }
            })
            .catch((err) => {
              this.$message({
                message: "缴费失败",
                type: "error",
              });
              clearInterval(this.timeN);
            });
        }, 1000);
      });
    },
    hanldeClose () {
      clearInterval(this.timeN);
      this.$refs.qrcodeRef.innerHTML = "";
      this.$refs.rechargeRef.close();
      this.$refs.addModalRef.close();
      this.getDataList();
    },
  },
  mounted () {
    this.getDataList();
  },
  beforeDestroy () {
    clearInterval(this.timeN);
    clearTimeout(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.upload-imgx-box {
  position: relative;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  display: inline-block;

  .upload-img {
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    right: -10px;
    top: -10px;
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid rgb(110, 108, 108);
    border-radius: 50%;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
  }
}
</style>
